import cookie from "cookie";
import moment from "moment-timezone";
import { ProductSteps } from "../constants/ProductOptions";
import { PHP_TO_MOMENT_REPLACEMENTS, ProductInternalTypeEnum } from "../constants/options";
import routes from "../constants/routes";
import Compressor from "compressorjs";
import { setAxiosHeader } from "../libs/axios";
import Sentry from "../libs/sentry";
import { UserPlatform } from "../types/AuthServicesTypes";
import { getDomainTimezone } from "./TenantHelper";
const jsonToQueryString = (json) => `?${Object.keys(json).filter((key) => Boolean(json[key])).map((key) => {
  return `${encodeURIComponent(key)}=${encodeURIComponent(json[key] ?? "")}`;
}).join("&")}`;
const deepClone = (array) => {
  return JSON.parse(JSON.stringify(array));
};
const getDomainName = (withPort) => {
  if (typeof window !== "undefined") {
    let url = window.location.hostname;
    url = url.replace(/(https?:\/\/)?(www.)?/i, "");
    url = url.split(".");
    url = url.slice(url.length - 2).join(".");
    if (url.indexOf("/") !== -1) {
      return url.split("/")[0];
    }
    const ports = ["80", "443"];
    if (withPort && !ports.includes(`${window.location.port}`)) {
      return `${url}:${window.location.port}`;
    }
    return url;
  }
};
const getSubDomain = (url) => {
  const urlParts = url.split(".");
  if (urlParts.length >= 3 && urlParts[0] !== "www") {
    return urlParts[0];
  } else {
    return null;
  }
};
const getDateInDomainTimezone = (date, format, tenantTimezone) => {
  const domainTimezone = getDomainTimezone();
  let mDate = date;
  if (date && date instanceof Date) {
    const year2 = date?.getFullYear();
    const month2 = date?.getMonth();
    const day2 = date?.getDate();
    mDate = moment(mDate).set({ year: year2, month: month2, date: day2 });
  }
  let newDate = /* @__PURE__ */ new Date();
  const year = newDate?.getFullYear();
  const month = newDate?.getMonth();
  const day = newDate?.getDate();
  const timezoneLocal = date ? moment.tz(mDate, tenantTimezone ?? domainTimezone) : moment.tz(moment().set({ year, month, date: day }), tenantTimezone ?? domainTimezone);
  if (format) {
    return timezoneLocal.format(phpToMoment(format));
  }
  return timezoneLocal;
};
const toLocalDate = (date) => {
  let mDate = date;
  if (date && date instanceof Date) {
    const year2 = date?.getFullYear();
    const month2 = date?.getMonth();
    const day = date?.getDate();
    mDate = moment(mDate).set({ year: year2, month: month2, date: day });
  } else {
    mDate = moment(mDate);
  }
  var year = mDate?.year();
  var month = mDate?.month();
  var dt = mDate?.date();
  var dateObject = /* @__PURE__ */ new Date();
  dateObject.setFullYear(year, month, dt);
  return dateObject;
};
function getDaysDifferenceBasedonDates(startDate, endDate) {
  const start_date = startDate.toDate();
  const end_date = endDate.toDate();
  const timeDifference = end_date.getTime() - start_date.getTime();
  const daysDifference = Math.ceil(timeDifference / (1e3 * 60 * 60 * 24));
  return daysDifference;
}
function isClosureBetweenRange(startDate, endDate, excludeDates) {
  const momentStartDate = moment(startDate, "YYYY-MM-DD");
  const momentEndDate = moment(endDate, "YYYY-MM-DD");
  const isSelectionDateBetweenExcludedDates = excludeDates?.some((excludedDate) => {
    const currentDate = moment(excludedDate, "YYYY-MM-DD");
    return currentDate.isBetween(momentStartDate, momentEndDate, null, "[]");
  });
  return isSelectionDateBetweenExcludedDates;
}
const getBaseUrl = () => {
  if (typeof window !== "undefined") {
    const hostName = window.location.hostname;
    if (process.env.NODE_ENV === "development") {
      return window.location.host.split(".").slice(1).join(".");
    }
    return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
  }
};
const transformToDateTimeString = (date, time) => {
  console.log("time: ", time);
  const formattedDate = moment(date).format("YYYY-MM-DD");
  const formattedTime = moment(time).format("HH:mm:ss");
  return `${formattedDate} ${formattedTime}`;
};
const phpToMoment = (str) => {
  return str.split("").map((chr) => chr in PHP_TO_MOMENT_REPLACEMENTS ? PHP_TO_MOMENT_REPLACEMENTS[chr] : chr).join("");
};
const toUtcTime = (localDate, format, isStartOfDate = true) => {
  const timezone = getDomainTimezone();
  let date = moment.tz(localDate, timezone);
  if (isStartOfDate) {
    date = date.startOf("day");
  }
  const mDate = date.utc();
  return format ? mDate.format(phpToMoment(format)) : mDate.format();
};
const convertOnlyDateToUTC = (localDate, format) => {
  const timezone = getDomainTimezone();
  const startAtTimezone = moment.tz(moment(localDate).format("YYYY-MM-DD"), timezone);
  const startOfDayPacific = startAtTimezone.startOf("day");
  const startOfDayUTC = startOfDayPacific.clone().utc();
  return format ? startOfDayUTC.format(phpToMoment(format)) : startOfDayUTC;
};
const convertOnlyDateToUTCEndOfDay = (startAtTimezone, format) => {
  const timezone = getDomainTimezone();
  const startOfDayPacific = startAtTimezone.endOf("day");
  const endOfDayUTC = startOfDayPacific.clone().utc();
  return format ? endOfDayUTC.format(phpToMoment(format)) : endOfDayUTC;
};
const getMinDateFromDomainTimezone = (localDate) => {
  const domainTimezone = getDomainTimezone();
  const momentTimezone = localDate ? moment.tz(localDate, domainTimezone) : moment.tz(moment(), domainTimezone);
  const year = momentTimezone.year();
  const month = momentTimezone.month();
  const date = momentTimezone.date();
  const hours = momentTimezone.hours();
  const minutes = momentTimezone.minutes();
  const seconds = momentTimezone.seconds();
  const milliseconds = momentTimezone.milliseconds();
  return new Date(year, month, date, hours, minutes, seconds, milliseconds);
};
const dateFormat = (localDate, format) => {
  const domainTimezone = getDomainTimezone();
  let strTime = "";
  const date = moment.tz(localDate, domainTimezone);
  if (format) {
    strTime = date.format(phpToMoment(format));
  } else {
    strTime = date.format();
  }
  return strTime;
};
const dateFormatWithoutTimezone = (date, format) => {
  let mDate = date;
  if (date && date instanceof Date) {
    const year = date?.getFullYear();
    const month = date?.getMonth();
    const day = date?.getDate();
    mDate = moment().set({ year, month, date: day });
  }
  return mDate;
};
const getLocalTimeFromDateAndTime = (date, time, format) => {
  const domainTimezone = getDomainTimezone();
  let mDate = moment(`${date} ${time}:00 +00:00`).tz(domainTimezone);
  if (format) {
    mDate = mDate.format(phpToMoment(format));
  }
  return mDate;
};
const getLocalTimeFromDateTime = (date, time, format) => {
  const domainTimezone = getDomainTimezone();
  let mDate = moment.utc(`${date} ${time}`).toDate();
  if (format && mDate) {
    mDate = moment(mDate).format(phpToMoment(format));
  }
  return mDate;
};
const getAmPmFromHISTime = (time) => {
  const timeAMPM = moment(time, "HH:mm:ss");
  const formattedTime = timeAMPM.format("h:mm A");
  return formattedTime;
};
const getHISFromAmPmTime = (time) => {
  const timeAMPM = moment(time, "h:mm A");
  const formattedTime = timeAMPM.format("HH:mm:ss");
  return formattedTime;
};
const getUTCTimeFromLocalDateTime = (date, time, format) => {
  const domainTimezone = getDomainTimezone();
  let mDate = moment(`${date} ${time}`).clone().utc();
  if (format && mDate) {
    mDate = mDate.format(phpToMoment(format));
  }
  return mDate;
};
const utcTimeToLocalTime = (date, time) => {
  const domainTimezone = getDomainTimezone();
  const timeIn24hrFormat = moment(time, "hh:mmA").format("HH:mm:ss");
  return moment.tz(date + " " + timeIn24hrFormat, domainTimezone);
};
const fromUtcToLocalTime = (date, format) => {
  const domainTimezone = getDomainTimezone();
  const clonedDate = moment(date).clone();
  const stillUtc = moment.utc(clonedDate).toDate();
  const localTime = moment(stillUtc).tz(domainTimezone);
  const outputFormat = format ? phpToMoment(format) : void 0;
  if (localTime && localTime.format) {
    return localTime?.format(outputFormat);
  }
  return localTime;
};
const fromUtcToLocalDate = (date, format) => {
  const domainTimezone = getDomainTimezone();
  const cleanedDate = date?.replace("T", " ") ?? "";
  const [datePart, timePart] = cleanedDate?.split(" ");
  const localTime = moment.tz(datePart, domainTimezone);
  const outputFormat = format ? phpToMoment(format) : void 0;
  return localTime.format(outputFormat);
};
const getTime = (date, format) => {
  const timezone = getDomainTimezone();
  let strTime = "";
  const momentDate = moment.tz(date, timezone);
  if (format === "12h") {
    strTime = momentDate.format(phpToMoment("h:i a"));
  } else if (format === "24h") {
    strTime = momentDate.format(phpToMoment("H:i"));
  }
  return strTime;
};
const getSelectedOptionsByVal = (options, val, defaultOptions) => {
  const obj = options.find((opt) => opt.value === val);
  if (!obj && defaultOptions) {
    return defaultOptions;
  }
  return obj;
};
const getCountryOptions = (countries = {}) => {
  const USA = countries.USA;
  const CAN = countries.CAN;
  const countryOptions = Object.keys(countries).map((index) => ({
    value: countries[index].code,
    label: countries[index].name
  })).sort((a, b) => a.label.localeCompare(b.label)).filter((item) => item.value !== "US" && item.value !== "CA");
  if (USA) {
    countryOptions.unshift({
      value: USA.code,
      label: USA.name
    });
  }
  if (CAN) {
    countryOptions.unshift({
      value: CAN.code,
      label: CAN.name
    });
  }
  return countryOptions;
};
const findCurrencies = (code, currencies = []) => {
  const currency = currencies.find((item) => item.code === code);
  let option = null;
  if (currency) {
    option = {
      label: `${currency.name} (${currency.code})`,
      value: currency.code,
      symbol: currency.symbol
    };
  }
  return option;
};
const getProductNameById = (id, products = []) => {
  const item = products.find((prod) => prod.id === id);
  return item && item.label;
};
const gotoProductNextStep = async (navigate, currentStep, id, qs, steps, pType) => {
  try {
    const p_steps = steps || ProductSteps;
    const slug = p_steps[currentStep + 1]?.value;
    switch (pType) {
      case ProductInternalTypeEnum.MERCHANDISE:
        return await navigate.push(`${routes.merchandise[slug].replace(":id", id)}${qs ? qs : ""}`);
      case ProductInternalTypeEnum.MEMBERSHIP:
        return await navigate.push(`${routes.membership[slug]?.replace(":id", id)}${qs ? qs : ""}`);
      default:
        return await navigate.push(`${routes.product[slug].replace(":id", id)}${qs ? qs : ""}`);
    }
  } catch (e) {
    Sentry.captureException(e);
    console.error({ e });
  }
};
const gotoProductPreviousStep = async (navigate, currentStep, id, qs, steps, pType) => {
  try {
    if (currentStep <= 0) {
      return;
    }
    const P_steps = steps || ProductSteps;
    const slug = P_steps[currentStep - 1]?.value;
    switch (pType) {
      case ProductInternalTypeEnum.MERCHANDISE:
        return await navigate.push(`${routes.merchandise[slug].replace(":id", id)}${qs ? qs : ""}`);
      case ProductInternalTypeEnum.MEMBERSHIP:
        return await navigate.push(`${routes.membership[slug]?.replace(":id", id)}${qs ? qs : ""}`);
      default:
        return await navigate.push(`${routes.product[slug].replace(":id", id)}${qs ? qs : ""}`);
    }
  } catch (e) {
    Sentry.captureException(e);
    console.error({ e });
  }
};
function getUniqueArrayByKey(arr, key) {
  const uniqueValues = /* @__PURE__ */ new Map();
  for (const item of arr) {
    const keyValue = item[key];
    if (!uniqueValues.has(keyValue)) {
      uniqueValues.set(keyValue, item);
    }
  }
  return Array.from(uniqueValues.values());
}
function isObjectBlank(obj) {
  if (Object.keys(obj).length === 0) {
    return true;
  }
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== "" && obj[key] !== void 0) {
      return false;
    }
  }
  return true;
}
const parseProductInfo = ({
  id,
  name,
  code,
  description,
  visibility,
  status,
  type,
  categories,
  deposit,
  widget_description,
  merchandise
}) => {
  const cat = categories;
  return {
    id,
    name,
    code,
    description,
    visibility,
    status,
    type,
    deposit: {
      deposit_type: deposit?.type,
      deposit_amount: deposit?.amount
    },
    product_type_id: type?.id,
    widget_description,
    categories: categories.map((category) => category.id),
    category_id: cat ? cat[0].id : "",
    merchandise
  };
};
const capitalizeFirstLetter = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};
const getConfigAndIdFromServerContext = (context) => {
  const id = context.query.id;
  const cookieHeader = context.req.headers.cookie;
  const cookies = cookie.parse(cookieHeader || "");
  setAxiosHeader("token", cookies.token);
  setAxiosHeader("x-tenant", cookies["x-tenant"]);
  setAxiosHeader("x-sub-domain", cookies["x-sub-domain"]);
  const config = {
    headers: {
      Authorization: `Bearer ${cookies.token}`,
      "x-tenant": cookies["x-tenant"],
      "x-sub-domain": cookies["x-sub-domain"]
    }
  };
  console.log({
    config
  });
  return {
    id,
    config,
    query: context.query,
    cookies
  };
};
const getOS = () => {
  const uA = navigator.userAgent;
  if (/iPad|iPhone|iPod/.test(uA) || uA.includes("Mac") && "ontouchend" in document)
    return "iOS";
  let i, os = ["Windows", "Android", "Unix", "Mac", "Linux", "BlackBerry"];
  for (i = 0; i < os.length; i++)
    if (new RegExp(os[i], "i").test(uA))
      return os[i];
};
const getDevicePlatform = () => {
  const deviceDetector = (userAgent = "") => {
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      userAgent
    ) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      userAgent.substr(0, 4)
    )) {
      return true;
    }
    return false;
  };
  return deviceDetector(navigator.userAgent || navigator.vendor || window.opera) ? UserPlatform.MOBILEWEB : UserPlatform.DESKTOPWEB;
};
const isDayPast = (date, format) => {
  const currentDate = getDateInDomainTimezone();
  return moment(date, format ?? "YYYY-MM-DD").isBefore(currentDate, "day");
};
const calculatePaidLaterAmount = (totalAmount, depositAmount) => {
  if (totalAmount && depositAmount) {
    const paidLaterAmount = totalAmount - depositAmount;
    return paidLaterAmount;
  }
  return "$0.00";
};
const currencySymbolRegex = /^[^\d]+|[^a-zA-Z\d]+$/g;
const formatPhoneNumber = (countryCode, phoneNumber) => {
  const numericPhoneNumber = phoneNumber?.replace(/\D/g, "");
  return `${countryCode?.startsWith("+") ? countryCode : `+${countryCode ?? ""}`} (${numericPhoneNumber?.slice(0, 3)})-${numericPhoneNumber?.slice(3, 6)}-${numericPhoneNumber?.slice(6)}`;
};
const getDayName = (dayNumber) => {
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const index = Math.min(Math.max(0, dayNumber), 6);
  return daysOfWeek[index];
};
const injectSubdomain = (url, subdomain) => {
  let parsedUrl = new URL(url);
  if (subdomain.length === 0) {
    return parsedUrl.toString();
  }
  parsedUrl.host = `${subdomain}.${parsedUrl.host}`;
  return parsedUrl.toString();
};
function getSubdomain() {
  let host = window.location.host;
  let parts = host.split(".");
  if (parts.length > 2) {
    parts.pop();
    parts.pop();
    if (parts.length === 1 && parts[0] === "www") {
      return "";
    }
    return parts.join(".");
  }
  return "";
}
const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};
const readMetaJsonData = () => {
  const scriptTag = document.getElementById("domaindetails");
  if (scriptTag) {
    const jsonData = scriptTag.getAttribute("data-json");
    if (jsonData) {
      try {
        const parsedJson = JSON.parse(jsonData);
        return parsedJson;
      } catch (error) {
        Sentry.captureException(error);
        console.error("Error parsing JSON:", error);
      }
    }
  }
  return {};
};
const toDomainDateFormat = (mDate, showTime) => {
  const parseDate = moment(mDate);
  const domainDetails = readMetaJsonData();
  const date_format = domainDetails?.date_format ?? "D, d M, Y";
  const time_format = domainDetails?.time_format ?? "12h";
  let strTime = "";
  if (showTime) {
    if (time_format === "12h") {
      strTime = phpToMoment("h:i a");
    } else if (time_format === "24h") {
      strTime = phpToMoment("H:i");
    }
  }
  return parseDate.format(phpToMoment(date_format) + " " + strTime);
};
const parseHexColor = (hex) => {
  hex = hex.replace(/^#/, "");
  const bigint = parseInt(hex, 16);
  const r = bigint >> 16 & 255;
  const g = bigint >> 8 & 255;
  const b = bigint & 255;
  return { r, g, b };
};
const formatRGB = (color) => `rgb(${color.r} ${color.g} ${color.b})`;
const lightenColor = (color, factor) => {
  const newR = Math.min(255, color.r + (255 - color.r) * factor);
  const newG = Math.min(255, color.g + (255 - color.g) * factor);
  const newB = Math.min(255, color.b + (255 - color.b) * factor);
  return formatRGB({ r: newR, g: newG, b: newB });
};
const darkenColor = (color, factor) => {
  const newR = color.r * (1 - factor);
  const newG = color.g * (1 - factor);
  const newB = color.b * (1 - factor);
  return formatRGB({ r: newR, g: newG, b: newB });
};
const generateColorVariants = (baseColor) => {
  const baseColorObj = parseHexColor(baseColor);
  const variant1 = lightenColor(baseColorObj, 0.6);
  const variant2 = lightenColor(baseColorObj, 0.43);
  const variant3 = darkenColor(baseColorObj, 0.1);
  const variant4 = darkenColor(baseColorObj, 0.2);
  const variant5 = darkenColor(baseColorObj, 0.375);
  return {
    400: variant1,
    500: variant2,
    700: variant3,
    800: variant4,
    900: variant5
  };
};
const getRGBColor = (hex, type) => {
  if (!hex) {
    return;
  }
  const color = generateColorVariants(hex);
  return `--color-${type}-400: ${color["400"]};
  --color-${type}-500: ${color["500"]};
  --color-${type}: ${hex};
  --color-${type}-700: ${color["700"]};
  --color-${type}-800: ${color["800"]};
  --color-${type}-900: ${color["900"]};
  --tw-ring-color: var(--color-primary);
  `;
};
const getCompressedImage = async (imageUrl) => {
  try {
    const compressedBlob = await new Promise((resolve, reject) => {
      new Compressor(imageUrl, {
        quality: 0.6,
        // Adjust the desired image quality (0.0 - 1.0)
        maxHeight: 2048,
        // Adjust the maximum height of the compressed image
        mimeType: "image/jpeg",
        // Specify the output image format
        success(result) {
          resolve(result);
        },
        error(error) {
          reject(error);
        }
      });
    });
    return compressedBlob;
  } catch (error) {
    Sentry.captureException(error);
    console.log(error);
  }
};
const getDayDifference = (startDate, endDate) => {
  const dayDifference = Math.abs(startDate.diff(endDate, "days"));
  return dayDifference;
};
const compressAndUploadImage = async (file) => {
  try {
    const compressedBlob = await new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        // Adjust the desired image quality (0.0 - 1.0)
        maxHeight: 2048,
        // Adjust the maximum height of the compressed image
        mimeType: "image/jpeg",
        // Specify the output image format
        success: async (compressedBlob2) => {
          console.log("compressedBlob: ", compressedBlob2);
          resolve(compressedBlob2);
        },
        error: (error) => {
          console.log("Error compressing image:", error);
          reject(error);
        }
      });
    });
    return compressedBlob;
  } catch (error) {
    Sentry.captureException(error);
    console.log("Error compressing image:", error);
    return error;
  }
};
const getDomainBasedDateAndTimeFormat = (date, isDateWithTime = false, showOnlyTime = false) => {
  const domainDetails = readMetaJsonData();
  if (showOnlyTime) {
    if (domainDetails?.time_format === "12h") {
      return fromUtcToLocalTime(date, `g:i A`);
    } else {
      return fromUtcToLocalTime(date, `H:i`);
    }
  } else {
    if (isDateWithTime) {
      if (domainDetails?.time_format === "12h") {
        return fromUtcToLocalTime(date, `${domainDetails?.date_format || "Y-m-d"} g:i A`);
      } else {
        return fromUtcToLocalTime(date, `${domainDetails?.date_format || "Y-m-d"} H:i`);
      }
    } else {
      return fromUtcToLocalTime(date, domainDetails?.date_format ?? "Y-m-d");
    }
  }
};
function sortAndUniqueTimeIntervals(intervals) {
  const uniqueMap = {};
  intervals.forEach((interval) => {
    uniqueMap[interval.label] = interval;
  });
  const uniqueIntervals = Object.values(uniqueMap);
  return uniqueIntervals.sort((a, b) => {
    const startA = moment(a.label.split("-")[0], "hh:mm A");
    const startB = moment(b.label.split("-")[0], "hh:mm A");
    return startA.diff(startB);
  });
}
const checkFirstAvalibilityDate = (dateString) => {
  const momentDate = moment.utc(dateString).local();
  if (moment().isAfter(momentDate)) {
    return null;
  }
  return toLocalDate(momentDate);
};
const mapDynamicTicketTypeLabels = (productUnitTypes, unitTypesLabelData) => {
  const optionsArray = [];
  if (productUnitTypes?.length && unitTypesLabelData) {
    productUnitTypes.forEach((data) => {
      const options = [];
      if (data.is_multi_day === 1 && data.has_flexible_timings === 0) {
        options.push({ value: "multi_day", label: unitTypesLabelData?.multi_day || "Multi-Day" });
      } else if (data.is_multi_day === 1 && data.has_flexible_timings === 1) {
        options.push({
          value: "multi_day_flex",
          label: "Multi-Day - Flexible Timings"
        });
      }
      if (data.is_multi_day === 0 && data.has_flexible_timings === 0) {
        options.push({
          value: "single_day",
          label: unitTypesLabelData?.single_day_fixed || "Single Day - Fixed Time"
        });
      } else if (data.is_multi_day === 0 && data.has_flexible_timings === 1) {
        options.push({
          value: "single_day_flex",
          label: unitTypesLabelData?.single_day_flexi || "Single Day - Flexible Timings"
        });
      }
      options.forEach((option) => {
        if (!optionsArray.some((existingOption) => existingOption.value === option.value)) {
          optionsArray.push(option);
        }
      });
    });
  }
  return optionsArray;
};
export {
  calculatePaidLaterAmount,
  capitalizeFirstLetter,
  checkFirstAvalibilityDate,
  compressAndUploadImage,
  convertOnlyDateToUTC,
  convertOnlyDateToUTCEndOfDay,
  currencySymbolRegex,
  dateFormat,
  dateFormatWithoutTimezone,
  deepClone,
  findCurrencies,
  formatDate,
  formatPhoneNumber,
  fromUtcToLocalDate,
  fromUtcToLocalTime,
  getAmPmFromHISTime,
  getBaseUrl,
  getCompressedImage,
  getConfigAndIdFromServerContext,
  getCountryOptions,
  getDateInDomainTimezone,
  getDayDifference,
  getDayName,
  getDaysDifferenceBasedonDates,
  getDevicePlatform,
  getDomainBasedDateAndTimeFormat,
  getDomainName,
  getHISFromAmPmTime,
  getLocalTimeFromDateAndTime,
  getLocalTimeFromDateTime,
  getMinDateFromDomainTimezone,
  getOS,
  getProductNameById,
  getRGBColor,
  getSelectedOptionsByVal,
  getSubDomain,
  getSubdomain,
  getTime,
  getUTCTimeFromLocalDateTime,
  getUniqueArrayByKey,
  gotoProductNextStep,
  gotoProductPreviousStep,
  injectSubdomain,
  isClosureBetweenRange,
  isDayPast,
  isObjectBlank,
  jsonToQueryString,
  mapDynamicTicketTypeLabels,
  parseProductInfo,
  phpToMoment,
  readMetaJsonData,
  sortAndUniqueTimeIntervals,
  toDomainDateFormat,
  toLocalDate,
  toUtcTime,
  transformToDateTimeString,
  utcTimeToLocalTime
};
